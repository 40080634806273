<template>
	<div>
	
		<template>
			<div id="breadcrumb-alignment" class="mb-1">
				<div class="d-flex justify-content-start breadcrumb-wrapper">
					<b-breadcrumb :items="breadCrumb()" />
				</div>
			</div>
		</template>

		<b-row class="match-height">
			<b-col lg="12" md="12">
		
				<b-card title="Add Feedback Request">

					<b-alert v-model="showDismissibleAlert" variant="danger" dismissible class="mb-1">
						<div class="alert-body">
							{{error_message}}
						</div>
					</b-alert>
				
					<b-form @submit="formSubmit">						
						<b-row>
							<b-col md="12">
								<b-row>

									<b-col md="6" >
										<b-form-group label="Project Site" class="required">
											<b-form-select v-model="form.site" @change="resetData(); getNoPhotoReporting();">
												<b-form-select-option value="" disabled>Select</b-form-select-option>
												<b-form-select-option :value="site._id" v-for="site in sites" :key="site._id">{{site.site_name}}</b-form-select-option>
											</b-form-select>
										</b-form-group>
									</b-col>

									<b-col md="6" >
										<b-form-group label="Location" class="required">
											<v-select
												v-model="form.locations"
												:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
												label="name"
												:options="locations"
												placeholder="Select"
												@input=getData()
												:clearable="false"
											/>
											<!-- <p class="text-warning" v-if="form.site != ''" @click="openModel()">
												<feather-icon icon="PlusCircleIcon" />
												<span class="align-middle ml-50" style="cursor: pointer">Add Location</span>
											</p> -->
										</b-form-group>
									</b-col>

								</b-row>
								

								<b-row class="mt-0" v-if="items.location != '' && items.location != null">

								  <b-col cols="12 pb-1">
									<h3 class="text-center mt-1 mb-1"><strong>Assigned Facilities</strong></h3>
								  </b-col>
        
							      <b-col md="3" cols="6" sm="6"  v-for="(category,index) in items.categories" :key="index">
							          <b-card
							            bg-variant="transparent"
							            :border-variant="category.selected == false ? 'secondary' : 'danger'"
							            text-variant="black"
							            @click="updateSelect(category)"
							            class="overflow-hidden"
							          >
							            
							              
							            <center>
							              <img :src="category.image" variant="light-primary" style="width:60%"/>
							            </center>
							                  
							            
							            
							            <h5 class="text-center mt-1 categoryH5">
							              {{category.name}}
							            </h5>
							          
							          </b-card>
							      
							      </b-col>

							      <b-col md="12" v-if="items.categories.length == 0 && items.location != '' && items.location != null" class="mb-1">
							      	<h5 class="text-center">No Facilities Found</h5>
							      </b-col>

							    </b-row>

								
								
								<b-row>
									<b-col class="daily_report_sign">
										<b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="warning" class="mr-1">
											Submit
										</b-button>

										<b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-secondary" @click="$router.push({ name:'feedback-request' })">
											Cancel
										</b-button>
									</b-col>
								</b-row>

							</b-col>
						</b-row>

					</b-form>
				</b-card>
			</b-col>
		</b-row>

		<b-modal
			id="add-location"
			ref="add-location"
			cancel-variant="outline-secondary"
			ok-variant="outline-warning"
			ok-title="Submit"
			cancel-title="Close"
			centered
			title="Add Location"
			@show="resetModal"
			@hidden="resetModal"
			@ok="addLocation"
		>
			<b-alert v-model="popshowDismissibleAlert" variant="danger" dismissible class="mb-1">
				<div class="alert-body">
					{{error_message}}
				</div>
			</b-alert>

			<b-form>
				<b-form-group>
					<label for="email">Location Name:</label>
					<!-- <b-form-input placeholder="" v-model="location_name" /> -->
					<vue-simple-suggest
						v-model="location_name"
						:list="location_list"
						:filter-by-query="true"
						placeholder="3 to 100 characters"
						:class="$store.state.appConfig.layout.skin == 'dark' ? 'darkSimpleSuggest' : 'lightSimpleSuggest'"
					>
					</vue-simple-suggest>
				</b-form-group>
			</b-form>
		</b-modal>
	
	</div>
</template>

<script>

import {
  	BCard,BRow,BCol,BFormGroup,BFormInput,BFormCheckbox,BForm,BButton,BAlert,BFormSelect,BFormSelectOption,BFormText,BInputGroupPrepend,
	BInputGroup,BMedia,BAvatar,BTable,BModal, BFormRadio,BFormTimepicker,BFormTextarea,BFormDatepicker,BBreadcrumb
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import { GET_API, POST_API } from "../../../store/actions.type";
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
import moment from "moment-timezone";
import vSelect from 'vue-select';
import DateRangePicker from 'vue2-daterange-picker';
//you need to import the CSS manually (in case you want to override it)
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import Datepicker from 'vuejs-datepicker';
import flatPickr from 'vue-flatpickr-component'
import VueSimpleSuggest from 'vue-simple-suggest'
import 'vue-simple-suggest/dist/styles.css' // Optional CSS

export default {
	components: {
		Datepicker,BAlert,BCard,BRow,BCol,BFormGroup,BFormInput,BFormCheckbox,BForm,BButton,BFormSelect,BFormSelectOption,BFormText,BInputGroupPrepend,BInputGroup,
		BMedia,BAvatar,BTable,BModal,VueCropper,BFormRadio,BFormTimepicker,DateRangePicker,BFormTextarea,vSelect,BFormDatepicker,flatPickr,BBreadcrumb, VueSimpleSuggest
	},

	directives: {
		Ripple,
	},

	data() {
		return {
			clearButton:true,
			error_message:null,
			showDismissibleAlert:false,
			popshowDismissibleAlert:false,
			doc_icon: require('@/assets/images/doc.png'),
			minutes:[],
			form :{
				
				site:'',
				locations:[],
				
			},
			highlighted : {
				dates: [
					new Date()
				]
			},
			showUrgent:'no',
			start_date:'',
			end_date:'',
			sites:[],
			locations:[],
			
			location_name:'',
			no_photo_reporting : 'no',
			location_list: [],

			items:{
				categories:[],
				location:null
			}
		}
	},

	methods : {

		addBI(){
			var bi = {
				image:null,
				name:'',
			}
			this.form.images.push(bi);
		},

		biInput(event,index){
			var input = event.target;
			var files = event.target.files

			if (input.files && input.files[0]) {
				var reader = new FileReader();
				reader.readAsDataURL(input.files[0]);
				var image = input.files[0];

				if (image.type != 'image/png' && image.type != 'image/jpeg' && image.type != 'image/jpg') {					
					Swal.fire({
						position: 'center',
						icon: 'warning',
						title: 'Please upload .png, .jpeg, .jpg image only',
						showConfirmButton: false,
						timer: 1500
						})
					this.$refs['biInput'+index][0].value=null;
				} else if (image.size > 2097152) {
					Swal.fire({
						position: 'center',
						icon: 'warning',
						title: 'Maximum 2 MB files allowed to be upload.',
						showConfirmButton: false,
						timer: 1500
						})					
					this.$refs['biInput'+index][0].value=null;
				} else {
					reader.onload = (e) => {
						this.form.images[index].image = e.target.result;
						this.form.images[index].name = image.name;
						this.form.images[index].type = 'new';
					}
				}
			}
		},

		removeBI(index){
			this.form.images.splice(index,1);
			if (this.form.images.length == 0) {
				this.form.images.push({ image:null,name:''});
			}
		},

		formSubmit(e){
			e.preventDefault();

			return this.$store.dispatch(POST_API, {
				data:{
                  items:this.items,
                  from:'user'
              },
              api: '/api/add-feedback'
               
			})
			.then(() => {
				if (this.$store.getters.containsErrors) {
					this.error_message = this.$store.getters.getErrors;
					this.showDismissibleAlert = true;
					window.scrollTo(0,0);
				} else {
					this.showDismissibleAlert = false;
					Swal.fire({
						position: 'center',
						icon: 'success',
						title: 'Record Added Successfully.',
						showConfirmButton: false,
						timer: 1500
					}).then((result) => {
						this.$router.push({ name:'feedback-request' })
					});
				}
			});
		},

		allSites(){
			return this.$store.dispatch(POST_API, {
				data:{
					role:this.$store.getters.currentUser.role,
					om_sites:this.$store.getters.currentUser.om_sites,
				},
				api: '/api/all-sites'
			})
			.then(() => {
				if (this.$store.getters.containsErrors) {
					this.error_message = this.$store.getters.getErrors;
					this.showDismissibleAlert = true;
					window.scrollTo(0,0);
				} else {
					this.showDismissibleAlert = false;
					var data  = this.$store.getters.getResults.data;
					this.sites = data;
					return this.sites;
				}
			});
		},

		allLocations(){
			return this.$store.dispatch(POST_API, {
				data:{
					site:this.form.site,
					type:"toilet"
				},
				api: '/api/all-locations'
			})
			.then(() => {
				if (this.$store.getters.containsErrors) {
					this.error_message = this.$store.getters.getErrors;
					this.showDismissibleAlert = true;
					window.scrollTo(0,0);
				} else {
					this.showDismissibleAlert = false;
					var data  = this.$store.getters.getResults.data;					
					this.locations = data;
					this.location_list = data.map(el => el.name);
					return this.locations;
				}
			});
		},

		allSupervisor(){
			return this.$store.dispatch(POST_API, {
				data:{
					site:this.form.site
				},
				api: '/api/all-supervisors'
			})
			.then(() => {
				if (this.$store.getters.containsErrors) {
					this.error_message = this.$store.getters.getErrors;
					this.showDismissibleAlert = true;
					window.scrollTo(0,0);
				} else {
					this.showDismissibleAlert = false;
					var data  = this.$store.getters.getResults.data;
					var sup = null;
					for (var i = 0; i < data.length; i++) {
						if (data[i].main_supervisor == 'yes') {
							this.form.supervisor = data[i]._id;
						}
					}
					this.form.supervisor = this.form.supervisor ? this.form.supervisor : '';
					this.supervisors = data;
					return this.supervisors;
				}
			});
		},

		resetData(){
			this.no_photo_reporting = 'no';
			this.form.locations = [];
			this.form.supervisor = '';
			this.allLocations();
			this.allSupervisor();
			if(this.form.site != '') {
				this.siteDetail();
			}else{
				this.showUrgent = 'no';
			}
		},

		getNoPhotoReporting(){
			this.sites.forEach((el) => {
				if(el._id == this.form.site){
					this.no_photo_reporting = el.no_photo_reporting;
				}
			})
		},

		resetModal(){
			// this.form.locations = [];
			this.location_name = '';
			this.popshowDismissibleAlert = false;
		},

		openModel(){
			// this.form.locations = [];
			this.$refs['add-location'].show();
		},

		addLocation(bvModalEvt){
			bvModalEvt.preventDefault()
		
			return this.$store.dispatch(POST_API, {
				data:{
					location_name:this.location_name,
					site:this.form.site
				},
				api: '/api/add-other-location'
			})
			.then(() => {
				if (this.$store.getters.containsErrors) {
					this.error_message = this.$store.getters.getErrors;
					this.popshowDismissibleAlert = true;
				} else {
					this.popshowDismissibleAlert = false;
					this.allLocations();
					this.$refs['add-location'].hide();
				}
			});
		},

		customFormatter(date) {
			return moment(date).format('DD MMM YYYY');
		},

		siteDetail(){
			return this.$store.dispatch(POST_API, {
				data:{
					id:this.form.site
				},
				api: '/api/site-detail'
			})
			.then(() => {
				if (this.$store.getters.containsErrors) {
					this.error_message = this.$store.getters.getErrors;
					this.showDismissibleAlert = true;
					window.scrollTo(0,0);
				} else {
					this.showDismissibleAlert = false;
					var data  = this.$store.getters.getResults.data;
					if (data.kpi_contract == 'yes') {
						this.showUrgent = 'yes';
						this.minutes = [];
						var n = [];
						data.minutes.forEach(item => {
							n.push(item.value);
						})
						this.minutes = n;					
					} else {
						this.showUrgent = 'no';
						this.minutes = [];
					}					
				}
			});
		},

		breadCrumb(){
			var item = [{
				to:{name:'client-dashboard'},
				text: 'Dashboard',
			},{
				to:null,
				text: 'Feedback System',
			},/* {
				to:null,
				text: 'Reports',
			}, */{
				to:{name: 'feedback-request'},
				text:'Feedback Request',
			},{
				to:null,
				text: 'Add Feedback Request',
				active:true
			}];
			return item;
		},
		getData(){
	      return this.$store.dispatch(POST_API, {
	         data:{
	           
	           location_id:this.form.locations._id,
	         },
	         api: '/api/qr-category-by-locations'
	      })
	      .then(() => {
	          if (this.$store.getters.containsErrors) {
	              this.error_message = this.$store.getters.getErrors;
	          } else {
	              var data = this.$store.getters.getResults.data;
	              this.items.categories = data.categories;
	              this.items.location = data.location;
	              
	              return this.items;
	          }
	      });
	    },
	    updateSelect(category){
	      if (category.selected == false) {

	        var selected = this.items.categories.reduce((acc,curr) => {
	          curr.selected == true ? acc++ : acc;
	          return acc
	        },0);
	        
	        if (selected < 3) {
	          return category.selected = true;
	        }else{
	          Swal.fire({
	            position : 'center',
	            icon     : 'warning',
	            title    : 'Maximum 3 facilities can be selected',
	            timer    : 1500,
	            showConfirmButton : false
	            
	          });
	        }

	      
	      }else{
	        return category.selected = false;
	      }
	    },
	},

	mounted(){
		this.allSites();
	}
}
</script>

<style lang="scss">
	@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style>

  .border-danger {
      border: 5px solid #ea5455 !important;
  }

  .border-secondary {
      border: 5px solid rgb(221, 221, 221) !important;
  }

  /*.card-body {
    min-height: 228px !important;
  }*/

</style>

